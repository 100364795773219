/* eslint import/no-cycle:0 */
import { combineReducers } from 'redux';
import { reducer as oidc } from 'redux-oidc-2';

import UserReducer from './userReducer';
import adminGroup from './adminGroupReducer';
import adminsReducer from './admins';
import assessment from './assessmentReducer';
import assignedProduct from './assignedProductReducer';
import availableProducts from './availableProductsReducer';
import cluster from './clusterReducer';
import display from './displayReducer';
import error from './errorReducer';
import examineeGroup from './examineeGroupReducer';
import examinees from './examineeReducer';
import group from './groupReducer';
import jobPostings from './jobPostingsReducer';
import jobs from './jobsReducer';
import products from './productReducer';
import timer from './timerReducer';

const reducers = combineReducers({
  oidc,
  adminGroup,
  admins: adminsReducer,
  assessment,
  assignedProduct,
  availableProducts,
  cluster,
  display,
  error,
  examineeGroup,
  examinees,
  group,
  jobPostings,
  jobs,
  products,
  timer,
  user: UserReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'ROOT_NUKE') {
    return reducers({}, action);
  }
  return reducers(state, action);
};

export default rootReducer;
