import React from 'react';
import { IntlProvider } from 'react-intl';

import {
  Wrapper,
} from './components';

const App = ({ locale, messages, children }) => (
  <IntlProvider locale={locale} messages={messages} defaultLocale="en">
    <Wrapper>
      {children}
    </Wrapper>
  </IntlProvider>
);

export default App;
