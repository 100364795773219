/*eslint-disable-next-line*/
import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';

// Is admin -> adminDashboard
// No gender, grade, or bio -> SetupDashboard
// No assignedProducts -> SetupDashboard
// otherwise -> examineeDashboard
const Dashboard = ({
  user,
  assignedProducts,
  // adminGroups,
  manualDashboard,
  allFinished,
  favoriteJobs,
}) => {
  if (user.isSuperAdmin && !manualDashboard) {
    return (<Navigate to="/super/dashboard" />);
  }
  /*
  if (adminGroups.length > 0 && !manualDashboard) {
    return (<Navigate to="/dashboard/admin" />);
  }
  */

  if (
    !user.gender
    || !user.grade
    || !user.dob
    || assignedProducts.length === 0
  ) {
    return (<Navigate to="/dashboard/setup" />);
  }

  if (allFinished && favoriteJobs.length >= 3) {
    return (<Navigate to="/dashboard/examinee/succeed" />);
  }
  if (allFinished) {
    return (<Navigate to="/dashboard/examinee/discover" />);
  }
  return (<Navigate to="/dashboard/examinee/explore" />);
};

Dashboard.propTypes = {
  user: PropTypes.shape({
    gender: PropTypes.string,
    grade: PropTypes.number,
    dob: momentPropTypes.momentObj,
  }),
};

Dashboard.defaultProps = {
  user: {},
};

export default Dashboard;
