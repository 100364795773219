import { connect } from 'react-redux';

import { broadcastFatalError } from '../../actions/errorActions';

import ProtectedAssessmentRouteComponent from '../components/ProtectedAssessmentRoute';

const mapStateToProps = (state, props) => {
  const {
    oidc: {
      user,
    },
    assessment: {
      chain,
    },
  } = state;

  return {
    user,
    chain,
    ...props,
  };
};

const mapDispatchToProps = (dispatch) => ({
  errorHandler: (err) => {
    dispatch(broadcastFatalError(err));
  },
});

const ProtectedAssessmentRouteContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProtectedAssessmentRouteComponent);

export default ProtectedAssessmentRouteContainer;
