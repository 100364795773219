import React from 'react';
import { Provider } from 'react-redux';
import { OidcProvider } from 'redux-oidc-2';
import { Outlet } from 'react-router-dom';

import configureStore from './store';
import App from './AppContainer';
import userManager from './utils/userManager';
import GlobalStyles from './utils/GlobalStyles';

const store = configureStore();

const Root = () => (
  <>
    <GlobalStyles />
    <Provider store={store}>
      <OidcProvider store={store} userManager={userManager}>
        <App>
          <Outlet />
        </App>
      </OidcProvider>
    </Provider>
  </>
);

export default Root;
