import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CallbackComponent } from 'redux-oidc-2';

import userManager from '../../utils/userManager';
import { CenteredSpinner } from '../../components';

const CallBack = ({ loadUser, errorHandler }) => {
  const navigate = useNavigate();
  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={(user) => {
        loadUser(user.profile.sub).then(() => {
          const path = user.state && user.state.postPath ? user.state.postPath : '/';
          navigate(path, { replace: true });
        });
      }}
      errorCallback={(error) => {
        console.log(error);
        errorHandler({
          status: error.status || 500,
          message: error.message === 'oops! something went wrong' ? 'An error occurred while trying to authenticate your request.' : error.message,
        });
      }}
    >
      <CenteredSpinner />
    </CallbackComponent>
  );
};

export default CallBack;
