/* eslint consistent-return: 0 */
import axios from 'axios';
import axiosRetry from 'axios-retry';

import { broadcastFatalError } from '../actions/errorActions';

const apiPath = process.env.REACT_APP_API || 'https://api.viasystem.com/v1/';

/**
 * Default axios instance.
 *
 * `import httpCli from './utils/httpCli';`
 *
 * This instance is used for all requests.
 * It has a retry and error interceptor.
 * The client tries all requests 5 times at an exponential delay.
 * The error interceptor handles all errors that are not 4xx.
 *
 * The error interceptor is bound in the store.js file.
 * It must be bound after the retry interceptor.
 * The auth tokens are bound in the userReducer.js file.
 */
const instance = axios.create({
  baseURL: apiPath,
});
axiosRetry(instance, {
  retries: 5,
  retryDelay: axiosRetry.exponentialDelay,
});

/**
 * An axios instance with no error handler or retry.
 *
 * `import { errorInterceptor } from './utils/httpCli';`
 *
 * The auth tokens are bound in the userReducer.js file.
 */
export const noErrorHandler = axios.create({
  baseURL: apiPath,
});

/**
 *  Default error interceptor.
 *
 * 4xx errors are passed through the chain.
 * 5xx errors are fatal and are not passed through the chain.
 * Network errors are fatal and are not passed through the chain.
 * Unknow errors are fatal and are not passed through the chain.
 *
 * This function is bound in the store.js file.
 * It must be bound after the retry interceptor.
*/
export const errorInterceptor = (store) => (
  (err) => {
    if (err.response && err.response.status >= 500) {
      // bad server
      store.dispatch(broadcastFatalError({
        status: err.response.data.statusCode,
        message: err.response.data.message,
      }));
    } else if (err.response) {
      // probably 4xx. Let it pass through.
      return Promise.reject(err);
    } else if (err.request || axiosRetry.isNetworkError(err)) {
      // client is offline or maybe the server
      store.dispatch(broadcastFatalError({
        status: 0,
        message: 'Network Error. Please check your internet connection',
      }));
    } else {
      // Unhandled errors. Probably fatal
      store.disptach(broadcastFatalError({
        status: 0,
        message: 'A unknown client error occured.',
      }));
    }
  }
);

export default instance;
