import { connect } from 'react-redux';
import { chain, reduce } from 'lodash';

import DashboardComponent from '../components/Dashboard';

import {
  isFinished as computeIsFinished,
} from '../../utils/products';

import {
  loadResults,
} from '../../actions/assessmentActions';

const mapStateToProps = (state) => {
  const {
    display: {
      manualDashboardSelection: manualDashboard,
    },
    user: {
      profile: user,
    },
    assignedProduct: {
      activeProduct,
      list: allAssignedProducts,
    },
    adminGroup: {
      list: adminGroups,
    },
    jobs: {
      favorites: favoriteJobs,
    },
  } = state;

  let isFinished = false;
  let allFinished = false;
  if (activeProduct) {
    isFinished = computeIsFinished(activeProduct);
    allFinished = reduce(isFinished, (acc, value) => {
      if (!value) {
        return false;
      }
      return acc;
    }, true);
  }

  const assignedProducts = chain(allAssignedProducts)
    .filter({ userId: user.id })
    .sortBy((o) => (o.updateAt))
    .value();

  return {
    allFinished,
    manualDashboard,
    user,
    assignedProducts,
    adminGroups,
    favoriteJobs,
  };
};

const mapDispatchToProps = () => ({
  loadResults: (prod) => {
    loadResults(prod, { loadJobs: true });
  },
});

const DashboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardComponent);

export default DashboardContainer;
