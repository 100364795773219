import { setChain } from '../../actions/assessmentActions';
import { getStatus, isFinished } from '../../utils/products';

export const mapStateToProps = ({
  type,
}) => (
  (state) => {
    const {
      oidc: {
        user: {
          profile: user,
        },
      },
      assignedProduct: {
        activeProduct,
      },
    } = state;

    return {
      type,
      user,
      testStatus: {
        values: getStatus('values', activeProduct),
        interests: getStatus('interests', activeProduct),
        abilities: getStatus('abilities', activeProduct),
      },
      isFinished: isFinished(activeProduct),
    };
  }
);

export const mapDispatchToProps = () => (
  (dispatch) => ({
    start: (testStatus) => {
      dispatch(setChain(testStatus));
    },
  })
);
