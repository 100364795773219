import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';

import {
  Fade,
  StepperContext,
} from '../../../components';
import { EnterPasscode } from '../../../forms';

const PasscodeP = styled.p`
  margin-top: 32px;
  margin-bottom: 0.25em;
`;

const SetupPasscode = ({
  assignedProducts,
}) => {
  const {
    next,
  } = useContext(StepperContext);

  useEffect(() => {
    if (assignedProducts.length > 0) {
      next();
    }
  }, [assignedProducts]);

  return (
    <Fade
      appear
      onExited={() => next()}
    >
      <div>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="passcodeForm">
          <PasscodeP>Enter the passcode provided by your administrator:</PasscodeP>
        </label>
        <EnterPasscode
          id="passcodeForm"
          name="passcodeForm"
          done={() => next()}
        />
      </div>
    </Fade>
  );
};

export default SetupPasscode;
