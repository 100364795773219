import { connect } from 'react-redux';
import AssessmentRouteComponent from '../components/AssessmentRouteComponent';

const mapStateToProps = (state, props) => {
  const {
    oidc: {
      user,
    },
    assessment: {
      chain,
      active: {
        testDefinitionId,
      },
      loading,
    },
    timer: {
      timeout,
      testDefinitionId: timerTestDefinition,
    },
  } = state;

  let isFinished = false;
  if (chain && chain[0]) {
    if (chain[0].testDefinition.testType.category === 'abilities') {
      isFinished = chain[0].testDefinition.testType.abbreviation !== props.type.toUpperCase();
    } else if (chain[0].testDefinition.testType.category !== props.type) {
      isFinished = true;
    } else {
      isFinished = chain[0].isFinished;
    }
  } else if (loading) {
    isFinished = false;
  } else {
    isFinished = true;
  }

  return {
    user,
    isFinished,
    chain,
    timeout: timeout && testDefinitionId === timerTestDefinition,
  };
};

const mapDispatchToProps = () => ({});

const AssessmentRouteContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AssessmentRouteComponent);

export default AssessmentRouteContainer;
