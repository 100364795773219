import { connect } from 'react-redux';

import App from './AppComponent';

import es from './compiled-lang/es.json';
import en from './compiled-lang/en.json';

const mapStateToProps = (state, props) => {
  let locale = 'en';
  let messages = en;

  const {
    assignedProduct: {
      activeProduct,
    },
  } = state;

  if (activeProduct && activeProduct.language === 'spanish') {
    locale = 'es';
    messages = es;
  }

  return {
    locale,
    messages,
    ...props,
  };
};

const mapDispatchToProps = () => ({});

const AppContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);

export default AppContainer;
