import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

import ScreenReader from './ScreenReader';

const SpinnerSpan = styled(FontAwesomeIcon)`
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  animation: spin 2.5s linear infinite
`;

const CenteredDiv = styled.div`
  flex: 1 1 auto;
  text-align: center;
  padding-top: 1em;
  justify-content: center;
`;

/** Spinner Object */
const Spinner = ({ className, ...rest }) => (
  <>
    <ScreenReader>Loading</ScreenReader>
    <SpinnerSpan
      icon={faSpinner}
      className={className}
      {...rest}
    />
  </>
);

Spinner.propTypes = {
  /** Adds additional classNames. Useful for expanding the size. */
  className: PropTypes.string,
};

Spinner.defaultProps = {
  className: '',
};

/**
 * Centered Spinner
 */
export const CenteredSpinner = ({ className }) => (
  <CenteredDiv>
    <Spinner
      className={classnames('fa-5x', className)}
    />
  </CenteredDiv>
);

export default Spinner;
