import React from 'react';
import { Navigate } from 'react-router-dom';
// import PropTypes from 'prop-types';

import AuthRoute from './AuthRoute';

const AssessmentRouteComponent = ({
  children,
  user,
  isFinished,
  timeout,
  noScroll,
  ...rest
}) => {
  if (!user || user.expired) {
    return (
      <AuthRoute
        user={user}
      >
        <h1>Loading</h1>
      </AuthRoute>
    );
  }

  if (timeout) {
    return (<Navigate to="/assessment/abilities/timeout" repalce />);
  }

  if (isFinished) {
    // The currently loaded test is finished. How did you get here?
    // Let the root try and figure that out
    return (<Navigate to="/" replace />);
  }

  return (
    <AuthRoute
      user={user}
      noScroll={noScroll}
      {...rest}
    >
      {children}
    </AuthRoute>
  );
};

export default AssessmentRouteComponent;
