import { connect } from 'react-redux';
import { filter } from 'lodash';

import SetupComponent from '../components/SetupComponent';
import { updateUser } from '../../../actions/userActions';

const mapStateToProps = (state) => ({
  user: state.user.profile,
  submittingUser: state.user.updating,
  assignedProducts: filter(state.assignedProduct.list, { userId: state.user.profile.id }),
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (values) => (
    dispatch(updateUser(values))
  ),
});

const SetupContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SetupComponent);

export default SetupContainer;
