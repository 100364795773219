import React from 'react';
import { Navigate } from 'react-router-dom';

import AuthRoute from './AuthRoute';

const PrivateAssessmentRouteComponent = ({
  children,
  user,
  path,
  chain,
  errorHandler,
  ...rest
}) => {
  if (chain.length === 0) {
    return (<Navigate to="/" replace />);
  }
  return (
    <AuthRoute
      user={user}
      path={path}
      errorHandler={errorHandler}
      {...rest}
    >
      {children}
    </AuthRoute>
  );
};

export default PrivateAssessmentRouteComponent;
