import React from 'react';

import userManager from '../../utils/userManager';

import { CenteredSpinner } from '../../components';

const Signout = () => {
  userManager.signoutRedirect();
  return <CenteredSpinner />;
};

export default Signout;
