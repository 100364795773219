import { USER_LOAD_ERROR } from 'redux-oidc-2';

import { FATAL_ERROR } from '../actions/errorActions';

const errorReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOAD_ERROR: {
      const fatalError = {
        status: action.payload.error.status || -1,
        message: action.payload.error.message || 'An unknown error occured',
      };
      return {
        ...state,
        fatalError,
      };
    }
    case FATAL_ERROR: {
      const fatalError = {
        status: action.payload.error.status || -1,
        message: action.payload.error.message || 'An unknown error occured',
      };
      return {
        ...state,
        fatalError,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default errorReducer;
