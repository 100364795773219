import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import {
  CenteredSpinner,
  ScrollToTop,
} from '../../components';
import userManager from '../../utils/userManager';

const AuthRoute = ({
  children,
  user,
  path,
  errorHandler,
  noScroll,
}) => {
  if (!user || user.expired || isEmpty(user)) {
    userManager.signinRedirect({
      data: {
        postPath: path,
      },
    }).catch((e) => {
      errorHandler(e);
    });
    return (
      <ScrollToTop>
        <CenteredSpinner />
      </ScrollToTop>
    );
  }
  if (noScroll) {
    return children;
  }
  return (
    <ScrollToTop>
      {children}
    </ScrollToTop>
  );
};

AuthRoute.propTypes = {
  user: PropTypes.shape({
    expired: PropTypes.bool,
  }),
  children: PropTypes.node.isRequired,
  errorHandler: PropTypes.func,
  noScroll: PropTypes.bool,
};

AuthRoute.defaultProps = {
  user: undefined,
  noScroll: false,
  errorHandler: () => {},
};

export default AuthRoute;
