import React from 'react';

import userManager from '../../utils/userManager';
import { CenteredSpinner } from '../../components';

const Signin = () => {
  userManager.signinRedirect();
  return <CenteredSpinner />;
};

export default Signin;
