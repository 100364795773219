import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Button,
  Fade,
  Stepper,
  StepperContext,
  Step,
} from '../../../components';

import SetupPasscode from './SetupPasscode';
import SetupUser from './SetupUser';
import SetupThankyou from './SetupThankyou';

const StepWrapper = styled.div`
  max-width:800px;
`;

const SetupComponent = ({
  user, assignedProducts, updateUser, submittingUser,
}) => {
  const {
    next,
  } = useContext(StepperContext);
  return (
    <Stepper noStepperBar>
      <Step name="welcome">
        <StepWrapper>
          <Fade
            appear
            timeout={1000}
          >
            <h1>Welcome</h1>
          </Fade>
          <Fade
            appear
          >
            <Button link full onClick={() => next()}>Next</Button>
          </Fade>
        </StepWrapper>
      </Step>
      <Step name="passcode">
        <StepWrapper>
          <SetupPasscode
            assignedProducts={assignedProducts}
          />
        </StepWrapper>
      </Step>
      <Step name="bio">
        <StepWrapper>
          <SetupUser
            user={user}
            updateUser={updateUser}
            submittingUser={submittingUser}
          />
        </StepWrapper>
      </Step>
      <Step name="thankyou">
        <StepWrapper>
          <SetupThankyou />
        </StepWrapper>
      </Step>
    </Stepper>
  );
};

SetupComponent.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  submittingUser: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  assignedProducts: PropTypes.arrayOf(
    PropTypes.object,
  ),
  updateUser: PropTypes.func.isRequired,
};

SetupComponent.defaultProps = {
  assignedProducts: [],
  submittingUser: undefined,
};

export default SetupComponent;
