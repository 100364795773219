import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Fade,
} from '../../../components';

const SetupThankyou = () => {
  const navigate = useNavigate();
  const [ timedOuted, setTimedOut ] = useState(false);
  return (
    <Fade
      appear
      show={!timedOuted}
      timeout={1000}
      onEntered={() => setTimedOut(true)}
      onExited={() => navigate('/')}
    >
      <div>
        <h1>Thank you!</h1>
      </div>
    </Fade>
  );
};

export default SetupThankyou;
